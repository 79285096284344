import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Links = ({ location }) => {
  const resourceStyles = {
    fontSize: '1.1rem',
    marginBottom: '1rem',
  };

  return (
    <Layout location={location}>
      <Seo title='Links' />
      <h2 style={{ fontSize: '1.3rem' }}>
        Below are links to several resources I hope you will find helpful
      </h2>
      <div>
        <ul style={{ listStyle: 'none' }}>
          <li style={resourceStyles}>
            <a
              href='https://www.everystudent.com/menus/experience.html'
              target='_blank'
              rel='noreferrer noopener'
              className='my-button'
            >
              Learn more about knowing God personally
            </a>
          </li>
          <li style={resourceStyles}>
            <a
              href='http://www.thoughts-about-god.com/biblestudies/spiritfilledlife.htm'
              target='_blank'
              rel='noreferrer noopener'
              className='my-button'
            >
              Learn more about experiencing God's power in your life
            </a>
          </li>
          <li style={resourceStyles}>
            <a
              href='https://jesus.net/c/who-is-jesus/'
              target='_blank'
              rel='noreferrer noopener'
              className='my-button'
            >
              Find out more about Jesus Christ
            </a>
          </li>
          <li style={resourceStyles}>
            <a
              href='http://www.freejesusfilm.org/'
              target='_blank'
              rel='noreferrer noopener'
              className='my-button'
            >
              Watch the film <em>JESUS</em>
            </a>{' '}
            <em>JESUS</em> was originally released through Warner Bros. and has
            been translated into more than 1,800 languages.
          </li>
          <li style={resourceStyles}>
            <a
              href='http://www.reasons.org/'
              target='_blank'
              rel='noreferrer noopener'
              className='my-button'
            >
              Reasons.org
            </a>{' '}
            My father who was a scientist and came to believe in Christ at 86
            liked Hugh Ross, the astronomer who created this ministry.
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Links;
